<template>
  <v-container>
    <v-row class="text-center" no-gutters>
      <v-col cols="12">
        <v-img alt="PARKING.COM" class="my-3" height="42" contain src="@/assets/logo_huge_drk.png" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card class="mx-auto rounded-lg" max-width="374">
          <v-dialog v-model="loading" persistent>
            <v-card class="pa-4">
              <v-progress-circular indeterminate color="red"></v-progress-circular>
              <span class="ml-4" style="font-size: 14px">Please wait...</span>
            </v-card>
          </v-dialog>
          <template slot="progress">
            <v-progress-linear color="red" height="10" indeterminate></v-progress-linear>
          </template>
          <v-card color="transparent" style="border-radius: 10px 10px 10px 10px;">
            <v-img class="rounded-t-lg " height="auto" :src="bookingDetails.hasOwnProperty('zone') &&
              bookingDetails.zone.image != '' &&
              bookingDetails.zone.image != null
              ? bookingDetails.zone.image
              : zoneImgURL
              " :lazy-src="bookingDetails.hasOwnProperty('zone') &&
    bookingDetails.zone.image != '' &&
    bookingDetails.zone.image != null
    ? bookingDetails.zone.image
    : zoneImgURL
                                " alt="Parking.com garage">
                            <v-row no-gutters>
                                <v-col> <v-overlay absolute></v-overlay>
                                </v-col>
                                <v-col cols="12">
                                    <div :class="{ booking_header: isPreAuthEnabled == false, preauth_header: isPreAuthEnabled == true, 'white--text': true, 'text-left': true, 'mx-4': true }"
                                        style="z-index: 100;">
                                        <span style="z-index: 10;">
                  {{
            bookingDetails.hasOwnProperty("booking")
              ? bookingDetails.booking.header
              : ""
          }}
                </span>
              </div>
                                </v-col>
                                <v-col cols="12" v-if="isPreAuthEnabled == true">
                                    <pre-auth-sheet
                                        style="position: absolute;bottom:0;z-index: 100;padding: 2px;"></pre-auth-sheet>

                </v-col>
              </v-row>


              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>

            </v-img>
          </v-card>
          <v-card-text v-if="exitDateTime > 0">
            <v-container fluid pa-0>

              <v-row class="mx-0 pb-0 black--text pl-2" no-gutters>
                <v-row no-gutters class="pb-4"><v-col>
                    <div>
                      Checked in at
                      {{
                        bookingDetails.hasOwnProperty("booking") ? booking_content : ""
                      }}

                    </div>
                    <span v-if="bookingDetails.hasOwnProperty('booking') &&
                      bookingDetails.hasOwnProperty('zone') &&
                      bookingDetails.booking.revEnd != null &&
                      bookingDetails.booking.revEnd != ''
                    ">

                      {{ checkout_content }}
                    </span>
                  </v-col></v-row>
              </v-row>
              <v-container fluid pa-0 style="margin-left: 4.5rem;">
                <v-row no-gutters>
                  <v-col cols="12" class="pl-1    ">
                    <v-row no-gutters class="counter-content-position">
                      <v-col cols="12" class="pa-0">
                        <v-icon class="material-icons-outlined pr-2" style="font-size: 50px">history_toggle_off</v-icon>
                        <v-skeleton-loader v-show="!bookingDetails.hasOwnProperty('booking')
                          " height="40px" max-width="200" type="heading"></v-skeleton-loader>
                        <span class="grey--text text--darken-1 time_ago" v-show="exitDateTime > 0
                          ">{{ countDownExitTime }}</span>
                        <p class="grey--text text--darken-1 time_ago_context pl-15 " style="margin-top: -15px;"
                          v-if="exitDateTime > 0">Remaining</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <!-- <v-row no-gutters class="mt-0">  
              <v-col cols="1">
                    <v-icon class="material-icons-outlined">history_toggle_off</v-icon>
                </v-col>
                <v-col>
                <p style="font-size:x-large;font-weight: bold;" class="mb-0">{{countDownExitTime}}</p>
                <p class="mb-0" style="font-size:12px" >Remaining</p>
              </v-col>
                      </v-row> -->

              <!-- <v-row no-gutters class="text-center mt-4">
                <v-col cols="12">
                  <p class="mb-0">Expires at {{ formattedExitTime }}</p>
                </v-col>
              </v-row> -->

                <v-card flat style="background-color: #F2555C14;border-radius: 10px">
                <v-row no-gutters>
                  <v-col cols="12" class="px-4 text-center " style="font-size: 12px">
                    <v-row no-gutters class="pt-2">
                      <v-col cols="6" class="text-left">
                        <p style="font-size: 14px; color: #222222" class="mb-0">
                          Parking
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" class="text-left">
                        <p style="font-size: 14px; color: #222222" class="text-right mb-0">
                          {{ bookingDetails.hasOwnProperty("booking") &&
                      bookingDetails.booking.hasOwnProperty("estimatedPrice") ? '$ ' +
                    Number(bookingDetails.booking.estimatedPrice).toFixed(2)
                      :
                      '-' }}

                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-2">
                      <v-col cols="6" class="text-left">
                        <v-row no-gutters>
                          <v-col cols="8">
                            <p style="font-size: 14px; color: #222222" class="mb-0">
                              Service Fee
                            </p>
                          </v-col>
                          <v-col cols="4" class="text-left">
                            <v-tooltip bottom v-model="show">

                              <template v-slot:activator="{ on, attrs }">
                                <v-icon class="material-icons-outlined" dark x-small color="primary" v-bind="attrs"
                                  @click="show = !show" v-on="on">info</v-icon>
                              </template>
                              <span>This fee helps cover the use of the technology that provides a better parking
                                experience.</span>
                            </v-tooltip>
                          </v-col>

                        </v-row>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" class="text-right">
                        <p style="font-size: 14px; color: #222222" class="text-right mb-0">
                          {{ bookingDetails.hasOwnProperty("booking") &&
                      bookingDetails.booking.hasOwnProperty("estimatedFee") ? '$ ' +
                    bookingDetails.booking.estimatedFee
                      :
                      '-' }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-2" v-if="(bookingDetails.hasOwnProperty('booking') &&
                      bookingDetails.booking.hasOwnProperty('validated') && bookingDetails.booking.validated =='1')">
                      <v-col cols="6" class="text-left">
                        <p style="font-size: 14px; color: #222222" class="mb-0">
                          Discount
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" class="text-right">
                        <p style="font-size: 14px; color: #222222" class="text-right mb-0">
                          - {{ bookingDetails.hasOwnProperty("booking") &&
                      bookingDetails.booking.hasOwnProperty("validatedAmount") ? '$ ' +
                    bookingDetails.booking.validatedAmount.toFixed(2)
                      :
                      '-' }}

                        </p>
                      </v-col>
                    </v-row>
                    <hr class="my-2" style="border: 1px solid #FDAE33;"/>
                    <v-row no-gutters class="mt-2">
                      <v-col cols="6" class="text-left">
                        <p style="font-size: 16px; font-weight: bold;color:#F2555C;font-size:15px">
                          Total Amount
                        </p>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="6" class="text-right">
                        <p style="font-size: 16px;font-weight: bold;color:#F2555C" class="text-right">
                          {{ bookingDetails.hasOwnProperty("booking") &&
                      bookingDetails.booking.hasOwnProperty("estimatedTotal") ? '$ ' +
                    bookingDetails.booking.estimatedTotal
                      :
                      '-' }}

                        </p>
                      </v-col>
                      <v-col cols="12" class="text-center">
                        <p style="font-size: 12px; color: #222222; opacity: 60%;">
                        *Includes state and local taxes of {{ bookingDetails.hasOwnProperty("booking") && bookingDetails.booking.hasOwnProperty("estimatedTax") ? "$ " +
                          bookingDetails.booking.estimatedTax.toFixed(2) : "-" }}
                      </p>
                      </v-col>
                    </v-row>
                  </v-col>
              </v-row>
              </v-card>
              <v-row no-gutters align="center" class="my-2">
                <v-col cols="12" class="pa-0 align-self-center text-center">
                  <v-btn class="elevation-0 white--text" tile x-small color="green darken-2" v-show="bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.booking.hasOwnProperty('validated') &&
                    bookingDetails.booking.validated == '1'
                    ">VALIDATED</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-text v-else-if="exitDateTime < 0">
            <v-row class="text-center mt-2">
              <v-col cols="12" class="black--text">
                <p class="text-body-2 font-weight-bold">Checkout In Progress</p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mx-0">
              <v-col class="pa-1">
                <p class="black--text">
                  {{
                    bookingDetails.hasOwnProperty("booking")
                      ? bookingDetails.booking.header
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="" class="align-self-center text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
                bookingDetails.booking.TypeOfBooking == 'Monthly'
                ">
                <v-btn class="elevation-0 white--text" tile x-small color="green darken-2">Monthly</v-btn>
              </v-col>
              <v-col cols="" class="align-self-center text-center" v-show="bookingDetails.hasOwnProperty('booking') &&
                bookingDetails.booking.hasOwnProperty('TypeOfBooking') &&
                bookingDetails.booking.TypeOfBooking == 'Employee'
                ">
                <v-btn class="elevation-0 white--text" tile x-small color="green darken-2">Employee</v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0"
              v-if="bookingDetails.hasOwnProperty('booking') && bookingDetails.hasOwnProperty('zone') && bookingDetails.booking.vehicleNo != null && bookingDetails.booking.vehicleNo != ''">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">License Plate :</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{ bookingDetails.hasOwnProperty("booking") ? bookingDetails.booking.vehicleNo : "" }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-1 mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header mb-0">Booked At :</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date mb-0">
                  {{
                    bookingDetails.hasOwnProperty("booking") &&
                      bookingDetails.booking.hasOwnProperty("entrytime")
                      ? formatDateTime(bookingDetails.booking.entrytime)
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters class="mx-0">
              <v-col cols="4" class="text-left pa-1">
                <p class="booking_date_header">Expired At :</p>
              </v-col>
              <v-col class="text-right pa-1">
                <p class="booking_date">
                  {{
                    bookingDetails.hasOwnProperty("booking") &&
                      bookingDetails.booking.hasOwnProperty("exittime")
                      ? formatDateTime(bookingDetails.booking.exittime)
                      : ""
                  }}
                </p>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="bookingDetails.hasOwnProperty('booking') &&
              bookingDetails.booking.state != '11'
            ">
              <v-col cols="12" class=" text-center " style="font-size: 12px">
                <v-row no-gutters class="pt-2 px-3">
                  <v-col cols="4" class="text-left">
                    <p style="font-size: small" class="black--text">
                      Parking
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" class="text-left">
                    <p style="font-size: small" class="black--text text-right">
                      {{ bookingDetails.hasOwnProperty("booking") &&
                        bookingDetails.booking.hasOwnProperty('estimatedPrice') ? "$ " +
                      Number(bookingDetails.booking.estimatedPrice).toFixed(2) : "-" }}

                    </p>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-0 px-3">
                  <v-col cols="4" class="text-left">
                    <v-row no-gutters>
                      <v-col cols="11">
                        <p style="font-size: small" class="black--text">
                          Service Fee
                        </p>
                      </v-col>
                      <v-col cols="1">
                        <v-tooltip bottom v-model="show">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="material-icons-outlined" dark x-small color="primary" v-bind="attrs"
                              @click="show = !show" v-on="on">info</v-icon>
                          </template>
                          <span>This fee helps cover the use of the technology that provides a better parking
                            experience.</span>
                        </v-tooltip>
                      </v-col>

                    </v-row>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" class="text-left">
                    <p style="font-size: small" class="black--text text-right">
                      {{ bookingDetails.hasOwnProperty("booking") &&
                        bookingDetails.booking.hasOwnProperty('estimatedFee') ? "$ " +
                      Number(bookingDetails.booking.estimatedFee).toFixed(2) : "-" }}

                    </p>
                  </v-col>
                </v-row>
                <hr class="total" />
                <v-row no-gutters class="mt-2 px-3">
                  <v-col cols="6" class="text-left">
                    <p style="font-weight: bold;color:#F2555C;font-size:15px">
                      Purchase Total <sup>*</sup>
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="4" class="text-left">
                    <p style="font-size: small;font-weight: bold;color:#F2555C" class=" text-right">
                      {{ bookingDetails.hasOwnProperty("booking") &&
                        bookingDetails.booking.hasOwnProperty('estimatedTotal') ? "$ " +
                      Number(bookingDetails.booking.estimatedTotal).toFixed(2) : "-" }}

                    </p>
                  </v-col>
                  <p>
                    *Includes state and local taxes of {{ bookingDetails.hasOwnProperty("booking") &&
                      bookingDetails.booking.hasOwnProperty('estimatedTax') ? "$ " +
                    Number(bookingDetails.booking.estimatedTax).toFixed(2) : "-" }}

                  </p>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="exitDateTime > 0" class="text-center pt-0 mt-0 pb-10">
            <v-container class="pa-0" fluid>
              <v-row no-gutters>
                <v-col cols="12" class="px-2">
                  <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                    @click="openExtDialog()">Extend
                    Parking</v-btn>
                </v-col>
              </v-row>

              <!-- QR code Dialog -->

              <v-dialog v-model="validateQRCodeDialog" width="fit-content" persistent>
                <v-card>
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-icon
                      @click="bookingDetails.zone.isDigitalValidationEnabledForCC == '1' ? validateQRCodeDialog = false : closeValidationQRDialog()">mdi-close</v-icon>
                  </v-card-title>
                  <v-card-text class="pa-1">
                    <validation-qr-code-scanner :scan="'validationCode'" v-bind:qrbox="250" v-bind:fps="30"
                      :cameraStatus="validationCameraStatus"></validation-qr-code-scanner>
                    <v-progress-circular v-if="!validationCameraStatus" class="validation-pending" indeterminate
                      color="red"></v-progress-circular>
                  </v-card-text>
                  <v-card-actions>
                    <v-container class="pa-0" fluid>
                      <v-row no-gutters>
                        <v-col cols="7">
                          <p class="grey--text font-weight-bold qr_text mr-4">Please scan the validation QR code</p>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-img src="@/assets/qr-code.png" style="opacity: 0.7" max-width="52"></v-img>
                      </v-row>
                    </v-container>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- Overall validate booking Dialog -->

              <v-dialog v-model="validateBookingDialog" width="fit-content">
                <v-card class="pa-0" ref="validatecontainer">
                  <v-card-title class="justify-center" style="position: relative">
                    <v-btn class elevation="0" color="white" dark fab large>
                      <v-icon x-large color="red accent-2">mdi-ticket-percent</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-container class="px-4 py-0" fluid>
                      <v-row no-gutters>
                        <v-col cols="12" class="text-left">
                          <p class="color-black text-center" style="font-size: 24px; line-height: 35px">
                            If the Merchant provided you a validation code, you may scan
                            it or enter it here.
                          </p>
                        </v-col>
                        <v-col cols="12">
                          <v-btn rounded block elevation="20" class="white--text exit_dialog_btn"
                            @click="openScanValidationCode()">Scan Validation Code</v-btn>
                        </v-col>
                        <v-col cols="12" class="text-left mt-4">
                          <p class="color-black" style="font-size: 24px; line-height: 35px">or enter it below</p>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field class="sms-input" color="red" v-model="validateBookingTxt" outlined
                            label="Validation code" hide-details="auto" v-on:keyup.enter="validateBooking()"
                            @focus="scrollToValidateBtn()" @mouseup="scrollToValidateBtn()"
                            @keydown="scrollToValidateBtn()" clearable height="80px"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-progress-circular v-if="validateBookingPending" class="validation-pending" indeterminate
                      color="red"></v-progress-circular>
                  </v-card-text>
                  <v-card-actions class="text-center">
                    <v-container pa-0 fluid>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-btn id="validatebookingbtn" rounded block elevation="20" :disabled="validateBookingTxt == '' ||
                            validateBookingTxt == null ||
                            validateBookingPending
                            " class="white--text exit_dialog_btn" @click="onValidateText()">SUBMIT</v-btn>
                        </v-col>
                        <v-col cols="12" class="mt-2">
                          <p style="cursor: pointer" @click="validateBookingDialog = false">Close</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="invalidValidationQRDialog" persistent max-width="290">
                <v-card class="pa-0">
                  <v-card-title class="justify-center" style="position: relative">
                    <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
                  </v-card-title>
                  <v-card-text class="text-center mt-5">
                    <span style="font-size: 20px">{{ invalidValidationQRMsg }}</span>
                  </v-card-text>
                  <v-card-actions class="text-center pb-6">
                    <v-container pa-0 fluid>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-btn rounded block elevation="20" class="white--text exit_dialog_btn"
                            @click="reScanValidationQR()">Re-Scan Code</v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="extensionDialog" fullscreen width="fit-content">
                <v-card>
                  <v-card-title style="background-color: #EDF1F4;">
                    <v-row no-gutters>
                      <v-col cols="11" class="text-center pl-10">
                        Extend Parking
                      </v-col>
                      <v-col>
                        <v-icon class="material-icons-outlined text-right"
                          @click="extensionDialog = false">close</v-icon>
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col>
                        <v-card
                            style="background-color:#F2555C14;border: 1px solid#F2555C80;border-radius: 10px !important;"
                            flat rounded class="mt-10 mb-4">
                            <v-row class="text-center mt-3" no-gutters>
                                <v-col cols="2" class="pl-2 pb-4">
                                    <v-icon class="material-icons-outlined"
                                        style="background-color: white;border-radius: 5px;"
                                        x-large>
                                        history_toggle_off</v-icon>
                                </v-col>
                                <v-col cols="10" class="text-left">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            Current parking expires at
                                        </v-col>
                                        <v-col cols="12" class="font-weight-bold">
                                            {{ formattedExitTime }}
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <!-- <p>Expires at {{ formattedExitTime }}</p> -->

                            </v-row>
                        </v-card>
                        <v-row no-gutters class="pb-4">
                          <v-col cols="1" class="text-right align-self-center">
                            <v-icon class="material-icons-outlined">schedule</v-icon>
                          </v-col>
                          <v-col cols="11" class="px-3"
                            v-if="timePickerConfig && bookingDetails.zone.timePickerConfiguration == '0'">
                            <v-text-field @click="openDatePicker()" readonly hide-details="auto"
                              placeholder="Select a duration to exit" v-model="formattedTime"></v-text-field>
                            <!-- v-if="selectedOption == 'date'" -->
                            <p class="black--text text-left pb-4"
                              style="padding-bottom: 0 !important;margin-bottom: 0 !important  ;">
                              {{ zoneEndTimeLabel }}</p>

                          </v-col>
                          <v-col cols="11" class="px-3"
                            v-if="timePickerConfig && bookingDetails.zone.timePickerConfiguration == '1'">
                            <v-text-field @click="openSphereTimePicker()" readonly
                              placeholder="Select a duration to exit" v-model="formattedTime"></v-text-field>
                          </v-col>
                        </v-row>
                        <SphereDateTimePicker :sheet="sheet" :resEndTime="resEndTime"
                          :maxParkingTime="onDemandZoneDetails.maxParkingTime || this.bookingDetails?.zone?.maxParkingTime"
                          :timezone="zoneDetails.timezone">
                        </SphereDateTimePicker>
                        <v-container fluid pa-0 v-if="estimate != null && Object.keys(estimate).length != 0"> <v-row
                            no-gutters class="pt-2 px-2">
                            <v-col cols="4" class="text-left">
                              <p style="font-size: small" class="black--text">
                                Parking
                              </p>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="4" class="text-left">
                              <p style="font-size: small" class="black--text text-right">
                                {{ estimate.hasOwnProperty("quotedRegularPrice") ? "$ " +
                                  estimate.quotedRegularPrice.toFixed(2) : "-" }}

                              </p>
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="mt-0 px-2">
                            <v-col cols="4" class="text-left">
                              <v-row no-gutters>
                                <v-col cols="11">
                                  <p style="font-size: small" class="black--text">
                                    Service Fee
                                  </p>
                                </v-col>
                                <v-col cols="1">
                                  <v-tooltip bottom v-model="show">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon class="material-icons-outlined" dark x-small color="primary"
                                        v-bind="attrs" @click="show = !show" v-on="on">info</v-icon>
                                    </template>
                                    <span>This fee helps cover the use of the technology that provides a better parking
                                      experience.</span>
                                  </v-tooltip>
                                </v-col>

                              </v-row>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="4" class="text-left">
                              <p style="font-size: small" class="black--text text-right">
                                {{ estimate.hasOwnProperty("totalFee") ? "$ " + estimate.totalFee.toFixed(2) : "-" }}
                              </p>
                            </v-col>
                          </v-row>
                          <hr class="total" />
                          <v-row no-gutters class="mt-2 px-2">
                            <v-col cols="6" class="text-left">
                              <p style="font-weight: bold;color:#F2555C;font-size:15px">
                                Purchase Total <sup>*</sup>
                              </p>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="4" class="text-left">
                              <p style="font-size: small;font-weight: bold;color:#F2555C" class=" text-right">
                                {{ estimate.hasOwnProperty("grandTotal") ? "$ " + estimate.grandTotal.toFixed(2) : "-"
                                }}

                              </p>
                            </v-col>
                            <p>
                              *Includes state and local taxes of {{ estimate.hasOwnProperty("totalTax") ? "$ " +
                                estimate.totalTax.toFixed(2) : "-" }}
                            </p>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="text-center pt-0 mt-0 pb-10">
                    <v-container class="pa-0" fluid>
                      <v-row no-gutters>
                        <v-col cols="12" class="px-2" v-if="!showAddPayment">
                          <v-btn rounded elevation="20" :loading="extendBtnLoading" :disabled="disableExtend"
                            @click="extendSession" class="add_licence_plate_btn white--text">Extend</v-btn>
                        </v-col>
                        <v-col cols="12" v-if="showAddPayment">
                          <v-btn rounded elevation="20" class="add_licence_plate_btn white--text"
                            @click="addPayment">Add
                            Payment</v-btn>
                          <p class="my-3 grey--text">Selected stay duration exceeds 24 hours. Click "Add Payment" to
                            extend beyond 24 hours by adding your credit card on file.</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-container>
          </v-card-actions>
          <v-col cols="12" class="mt-2 pa-0" v-if="(bookingDetails.hasOwnProperty('booking') &&
            bookingDetails.booking.hasOwnProperty('validated') &&
            bookingDetails.booking.validated != '1') && exitDateTime > 0">
            <hr class="discount" style="color: black !important;">
            <v-card flat color="#ececec" class="ma-0 rounded-lg pb-5" tile max-width="100%">
              <v-card-text class="pb-0">
                <v-row class="text-center pt-0" no-gutters>
                  <v-col cols="12" class="pa-0">
                    <p style="font-size: 15px;color: #F2555C;font-weight: bolder;">CLAIM
                      YOUR PARKING DISCOUNT</p>
                  </v-col>
                  <!-- <v-col cols="12">
                        <v-btn color="black" rounded class="pa-0 ma-0" width="230" height="50" elevation="20"
                          @click="loyalty = true">
                          <v-img src="../assets/newGoldenNugget.jpg" style="border-radius: 1.2rem;" class max-width="230"
                            height="60"></v-img>
                        </v-btn>
                      </v-col> -->
                </v-row>
                <v-col cols="12" class="mt-1 px-4 text-center">
                  <v-btn rounded elevation="20" dense width="230px" height="40px" style="font-size:15px" v-show="bookingDetails.hasOwnProperty('booking') &&
                    bookingDetails.booking.hasOwnProperty('validated') &&
                    bookingDetails.booking.validated != '1'" class="validate_btn white--text blue darken-2 no-upper-case px-4"
                    @click="bookingDetails?.zone?.isDigitalValidationEnabledForCC == '1' ? openScanValidationCode() : openValidationDialog()">Apply
                    Your Validation</v-btn>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="mt-4 pt-1" v-if="bookingDetails.hasOwnProperty('zone') &&
            bookingDetails.zone.hasOwnProperty('mySpotSettings') &&
            bookingDetails.zone.mySpotSettings != null &&
            bookingDetails.zone.mySpotSettings.hasOwnProperty(
              'parkingDisclosures'
            ) &&
            bookingDetails.zone.mySpotSettings.parkingDisclosures != null &&
            bookingDetails.zone.mySpotSettings.parkingDisclosures != ''
          ">
            <div class="alert-msg-box">
              <v-row no-gutters>
                <v-col cols="12" class="text-start">
                  <p class="pa-0 ma-0" style="
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 501;
                    ">
                    ALERT MESSAGE
                  </p>
                </v-col>
              </v-row>
              <p class="mt-2" v-html="bookingDetails.zone.mySpotSettings.parkingDisclosures" style="
                  font-size: 12px;
                  text-align: justify;
                  letter-spacing: normal;
                  line-height: 1.3;
                "></p>
            </div>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="persistentDialog" persistent max-width="290">
      <v-card class="pa-0 pb-5">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ persistentMsg }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alertDialog" persistent max-width="290">
      <v-card class="pa-0">
        <v-card-title class="justify-center" style="position: relative">
          <v-icon x-large color="red accent-2">mdi-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="text-center mt-5">
          <span style="font-size: 20px">{{ alertMsg }}</span>
        </v-card-text>
        <v-card-actions class="text-center pb-6">
          <v-container pa-0 fluid>
            <v-row no-gutters>
              <v-col cols="12">
                <v-btn rounded block elevation="0" class="white--text exit_dialog_btn"
                  @click="alertDialog = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dateDialog" max-width="350px">
      <v-date-picker color="#f2555c" v-model="selectedEndDate" :min="minDate" :max="maxDate" @change="setDateAndTime()">

      </v-date-picker>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import { differenceInMinutes, set, addDays, addMinutes, addHours, differenceInMilliseconds } from 'date-fns';
import SphereDateTimePicker from '@/uiComponents/SphereDateTimePicker.vue'
import PreAuthSheet from "@/uiComponents/PreAuthSheet.vue";

import { EventBus } from "@/lib/EventBus";
import APIHelper from "../../apiHelper";
// import axios from "axios";
// import QrCodeScanner from "@/views/QrCodeScanner.vue";
import { format, } from "date-fns";
import { dateToTimeZoneDate, format24TimeToAMPM } from "@/utils/formatDateTime";
import { secondsToHms } from "@/utils/formatDateTime";
import moment from 'moment'
// import {formatDateTime} from "@/utils/formatDateTime";
import ValidationQrCodeScanner from "@/views/ValidationQrCodeScanner.vue";
// import VueTimePicker from '@/uiComponents/VueTimePicker';
import rules from "@/utils/rules";
Vue.use(moment);
export default {
  data: (vm) => ({
    zoneEndTimeLabel: "",
    persistentDialog: false,
    persistentMsg: "",
    sheet: false,
    selectedEndDate: "",
    showAddPayment: false,
    stopAt: vm.odvExtension,
    show: false,
    dateDialog: false,
    alertDialog: false,
    loading: false,
    alertMsg: "",
    extendBtnLoading: false,
    getRateCall: false,
    windowHeight: window.innerHeight - 350,
    estimate: null,
    stopTime: "",
    exitTimeSelected: null,
    timePickerHrRange: null,
    timePickerMinRange: null,
    minDate: null,
    maxDate: null,
    Datemenu: false,
    exitDate: null,
    dateFormatted: null,
    validateBookingTxt: '',
    validateBookingDialog: false,
    validateBookingPending: false,
    validateQRCodePending: false,
    validateQRCodeDialog: false,
    camera: "auto",
    invalidQRMsg: "",
    invalidValidationQRMsg: "",
    invalidValidationQRDialog: false,
    invalidSMSMsg: "",
    cameraStatus: false,
    validationCameraStatus: false,
    odvExtension: '',
    Timemenu: false,
    rules: rules,
    licencePlate: '',
    extensionDialog: false,
    exitDateTime: 0
  }),
  components: {
    ValidationQrCodeScanner, SphereDateTimePicker,
    PreAuthSheet
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (from.path.includes('addpayment')) {
        await vm.getBookingDetails()
      }
      let searchParams = window.location.search.split("=");
      let searchParamKey = searchParams.length > 0 ? searchParams[0] : "";
      let searchParamValue = searchParams.length > 0 ? searchParams[1] : "";
      switch (searchParamKey) {
        case "?zcode":
          vm.getZoneDetails(searchParamKey, searchParamValue);
          break;
        case "?gateid":
          //await self.getZoneDetails(searchParamKey, searchParamValue);
          break;
      }
    });
  },
  methods: {
    /**
       * @method applyDuration - applies the duration selected from duration picker to the current time.
       * @param {*} duration - format {day:selectedDay, hour:selectedHour, minute:selectedMinute}
       */
    applyDuration(duration) {
      let endTime = this.zoneDetails.timezone ? new Date(this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date')) : new Date();
      let addedDays = addDays(endTime, duration.day);
      let addedhours = addHours(addedDays, duration.hour);
      let addedMinutes = addMinutes(addedhours, Number(duration.minute));
      this.odvExtension = addedMinutes;
    },
    setDateAndTime() {
      let exitDateAndTime = this.selectedEndDate + " " + this.bookingDetails?.zone?.customizedEndTime

      this.odvExtension = new Date(exitDateAndTime)
      this.dateTimeChange()

    },
    async getOnDemand() {
      try {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        console.log('self')
        var zoneDetails = await APIHelper(
        "GET",
        "/api/v1/getOnDemand/" + this.bookingDetails?.zone?.locationCode,
      );
      if(zoneDetails?.data?.lot==undefined || zoneDetails?.data?.lot==null || zoneDetails?.data?.lot=='')
      {
          this.persistentMsg = zoneDetails?.data?.message || ""
          this.persistentDialog = true;
      }
     
      this.$store.commit("SET_LOADING_STATUS", {
                    property: "APP",
                    pending: false,
                });

        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });

      } catch (e) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });

      }
    },
    openDatePicker() {
      this.dateDialog = true;

    },
    openSphereTimePicker() {
      //the exit date value for the extended time
      this.exitDate = this.formatUTCDefaultDate(this.bookingDetails?.booking?.exittime
        , 'exit');
      //formatted exit date value to display
      this.dateFormatted = this.formatUTCDefaultDate(this.bookingDetails?.booking?.exittime, 'exit');
      this.odvExtension = new Date(this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date'));
      EventBus.$emit('open-timepicker')
      this.sheet = true
    },
    async addPayment() {
      this.$router.replace({ path: "/addpayment" });
    },
    round(time) {
      let exitdate = time !== null && time !== '' ? format(time, 'yyyy/MM/dd') : new Date();
      let selectedMins = time !== null && time !== '' ? format(time, 'mm') : new Date();
      let selectedHours = time !== null && time !== '' ? format(time, 'hh') : new Date();
      let selectedAMPM = time !== null && time !== '' ? format(time, 'a') : new Date();
      if (exitdate != null && selectedMins != '15' && selectedMins != '30' && selectedMins != '45' && selectedMins != '00' && selectedMins != null && selectedMins != "" && selectedHours != null && selectedHours != "" && selectedAMPM != null && selectedAMPM != "") {
        let intervalMilliseconds = 900000
        let datetime = new Date(exitdate + " " + selectedHours + ":" + selectedMins + " " + selectedAMPM)
        let interval = datetime.getTime() % intervalMilliseconds;
        let delta = interval === 0 ? 0 : datetime.getTime() - interval;
        delta += intervalMilliseconds;
        // this.exitTimeSelected = Object.assign(this.exitTimeSelected, { hh: format(new Date(delta), 'hh'), mm: format(new Date(delta), 'mm'), a: format(new Date(delta), 'a'), })
        return new Date(delta)
      }
    },
    async extendSession() {
      try {
        //format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");
        this.extendBtnLoading = true;
        let exitDateTime = this.odvExtension != null || this.odvExtension !== undefined || this.odvExtension != "" ?
          format(this.odvExtension, 'yyyy-MM-dd')
          +
          " " +
          (Object.hasOwnProperty.call(this.zoneDetails, 'timePickerConfiguration') && this.zoneDetails.timePickerConfiguration == '0'
            ? format24TimeToAMPM(this.zoneDetails?.customizedEndTime) : format(this.odvExtension, 'HH:mm:ss')) : "";
        let data = {
          bid: this.bookingDetails?.booking?.bid,
          extendedTime: exitDateTime,//"2022-12-29T02:26:00.000",
        };
        var extendSessionRes = await APIHelper(
          "POST",
          "/api/v1/prepaid/extendReservation", data
        );
        if (extendSessionRes?.data?.message == "Successfully updated!!") {
          await this.getBookingDetails();
          this.extendBtnLoading = false;
          this.extensionDialog = false;
        } else {
          this.alertDialog = true;
          this.alertMsg = extendSessionRes?.data?.message || "Error in extending the session"
        }

      } catch (error) {
        this.extendBtnLoading = false;
        console.log(error);
      }
    },
    async getBookingDetails() {
      try {
        let bookingId = window.location.pathname.substring(3)
                var bookingDetails = await APIHelper(
                    "GET",
                    "/api/v1/booking/config/" + bookingId
                );
        // bookingDetails.data.serverName == 'dev' ? document.querySelector('meta[name="theme-color"]').setAttribute("content", "#43A047") : "";
        this.$store.commit(
          "SET_BOOKING_DETAILS",
          bookingDetails.data?.data ? bookingDetails.data.data : null
        );
      } catch (error) {
        console.log(error);
      }
    },
    async dateTimeChange() {
      try {
        if (this.disableAPGP) {
          console.log('')
        }
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: true,
        });
        //format(new Date(this.checkinDate), "yyyy-MM-dd'T'HH:mm:ss");      console.log()
        this.extendBtnLoading = true;
        this.startDate = this.bookingDetails?.booking?.entrytime
          ? format(new Date(this.formatExitDateTime(this.bookingDetails?.booking?.entrytime, 'date')), "yyyy-MM-dd'T'HH:mm:ss")
          : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
        let stopTime = Object.hasOwnProperty.call(this.bookingDetails, 'zone') && Object.hasOwnProperty.call(this.bookingDetails.zone, 'timePickerConfiguration') && this.bookingDetails.zone.timePickerConfiguration == '0' ? (this.bookingDetails.zone?.customizedEndTime ? this.bookingDetails.zone?.customizedEndTime : '23:59:00') : format(this.odvExtension, 'HH:mm:ss'); //?.hh+":"+this.exitTimeSelected.mm+":00"//format(this.checkoutDate, "yyyy-MM-dd'T'HH:mm:ss")
        let exitdate = this.odvExtension !== null && this.odvExtension !== undefined && this.odvExtension !== "" ? format(this.bookingDetails.zone.timePickerConfiguration == '0' && this.customEndTimeMinDate ? addDays(this.odvExtension, 1) : this.odvExtension, 'yyyy-MM-dd') : "";
        let stopDate = exitdate + "T" + stopTime;
        let data = {
          startAt: this.startDate,
          stopAt: stopDate,
          zcode: this.bookingDetails?.zone?.locationCode,
          bid: this.bookingDetails.booking.bid
        };
        var getRate = await APIHelper(
          "POST",
          "/api/v2/getRate", data
        );
        if (getRate?.data?.status == false) {
          this.dateDialog = false
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          this.alertDialog = true;
          this.alertMsg = getRate?.data?.message || "Error in getting rate details";
          EventBus.$emit('datetime-set');
        } else {
          this.estimate = Object.assign({}, getRate?.data?.data);
          this.zoneEndTimeLabel = `You are eligible to stay till ${format(this.stopAt, 'MM-dd-yyyy hh:mm a')}`
          EventBus.$emit('datetime-set');
          this.$store.commit("SET_LOADING_STATUS", {
            property: "APP",
            pending: false,
          });
          this.dateDialog = false

        }
        this.extendBtnLoading = false;

      } catch (error) {
        this.$store.commit("SET_LOADING_STATUS", {
          property: "APP",
          pending: false,
        });
        this.dateDialog = false

        this.extendBtnLoading = false;
        console.log(error);
      }
    },
    async openExtDialog() {
      this.loading = true
      var zoneDetails = await APIHelper(
        "GET",
        "/api/v1/getOnDemand/" + this.bookingDetails?.zone?.locationCode,
      );
      if (zoneDetails?.data?.lot == undefined && zoneDetails?.data?.lot == null && zoneDetails?.data?.lot == '') {

      this.persistentMsg = zoneDetails?.data?.message  || ""
      this.persistentDialog = true;
    }
      await this.getZoneDetails("?zcode", this.bookingDetails?.zone?.zcode);
      this.loading = false
      this.extensionDialog = true;
      // await axios.get(
      //   process.env.VUE_APP_ON_DEMAND_URL + "/" + "32899"+ ".json"
      // );
      this.$store.commit("SET_ON_DEMAND_ZONE_DETAILS", zoneDetails.data?.lot);
      this.setDefaultDate();
      // this.setTimePicker();
      this.extendBtnLoading = false;
    },

    setDefaultDate() {
      //the exit date value for the extended time
      this.exitDate = this.bookingDetails?.booking?.exittime ? this.formatUTCDefaultDate(this.bookingDetails?.booking?.exittime
        , 'exit') : "";
      //formatted exit date value to display
      this.dateFormatted =
        this.bookingDetails?.booking?.exittime ? this.formatUTCDefaultDate(this.bookingDetails?.booking?.exittime
          , 'format') : "";
      this.odvExtension = new Date(this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date'))
      this.minDate = format(this.odvExtension, 'yyyy-MM-dd');

      let maxParkingTime =
        this.bookingDetails != null
          ? Number(this.bookingDetails?.zone?.maxParkingTime?.days) *
          24 *
          60 *
          60 +
          Number(this.bookingDetails?.zone?.maxParkingTime?.hours) * 60 * 60 +
          Number(this.bookingDetails?.zone?.maxParkingTime?.minutes) * 60
          : null;
      let currentDateTime = new Date().getTime();

      //get max date in seconds based on max parking time
      let maxZoneDate =
        maxParkingTime != null ? currentDateTime + maxParkingTime * 1000 : null;
      //get max date with time and set it to maxDateTime
      this.maxDate = this.bookingDetails?.zone?.timezone
        ? format(
          dateToTimeZoneDate(
            new Date(maxZoneDate),
            this.bookingDetails?.zone?.timezone
          ),
          "yyyy-MM-dd"
        )
        : format(new Date(), "yyyy-MM-dd");
      this.bookingDetails?.zone?.timePickerConfiguration == '0' ? this.dateTimeChange() : ""


    },
    onTimePickerChange() {
      let exitdate = this.odvExtension !== null && this.odvExtension !== undefined && this.odvExtension !== "" ? format(this.odvExtension, 'yyyy/MM/dd') : "";
      let selectedMins = this.odvExtension !== null && this.odvExtension !== undefined && this.odvExtension !== "" ? format(this.odvExtension, 'mm') : "";
      let selectedHours = this.odvExtension !== null && this.odvExtension !== undefined && this.odvExtension !== "" ? format(this.odvExtension, 'HH') : "";
      let selectedAMPM = this.odvExtension !== null && this.odvExtension !== undefined && this.odvExtension !== "" ? format(this.odvExtension, 'a') : "";
      this.stopTime = selectedMins != null && selectedMins != "" && selectedHours != null && selectedHours != "" && selectedAMPM != null && selectedAMPM != "" ? selectedHours + ":" + selectedMins + ":" + "00" : "";
      let currenTime = this.bookingDetails.zone.timezone
        ? format(
          dateToTimeZoneDate(new Date(), this.bookingDetails.zone.timezone),
          "yyyy-MM-dd HH:mm:ss"
        )
        : format(new Date(), "yyyy-MM-dd  HH:mm:ss");
      // let currentDate = this.bookingDetails.zone.timezone
      //   ? format(
      //     dateToTimeZoneDate(new Date(), this.bookingDetails.zone.timezone),
      //     "yyyy-MM-dd"
      //   )
      //   : format(new Date(), "yyyy-MM-dd");
      if (
        exitdate != null && exitdate !== "" && exitdate !== "" && selectedMins != null && selectedMins != "" && selectedHours != null && selectedHours != "" && selectedAMPM != null && selectedAMPM != "" &&
        new Date(exitdate + " " + this.stopTime).getTime() >
        new Date(currenTime).getTime()
      ) {
        this.dateTimeChange();
      }
    },
    reScanValidationQR() {
      this.invalidValidationQRDialog = false;
      this.validationCameraStatus = true;
    },
    closeValidationQRDialog() {
      this.validationCameraStatus = false;
      this.validateBookingDialog = true;
      this.validateQRCodeDialog = false;
    },
    scrollToValidateBtn() {
      const el = document.querySelector("#validatebookingbtn");
      if (el) {
        el.scrollIntoView(this.options);
      }
    },
    async openScanValidationCode() {
      this.validateBookingDialog = false;
      this.validateBookingTxt = "";
      this.validateQRCodeDialog = true;
      this.validationCameraStatus = true;
    },
    async onValidateQRCode(decodedString) {
      this.validationCameraStatus = false;
      this.validateQRCodePending = true;
      try {
        let result = await this.validateBooking(decodedString);
        if (!result.data.status) {
          this.invalidValidationQRMsg = result?.data?.message
            ? result.data.message
            : "Validation not recognized. Please try again";
          this.invalidValidationQRDialog = true;
          this.validateQRCodePending = false;
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            error: this.invalidQRMsg,
            icon: "redeem",
            statusText:
              "Error in validating the session on scanning validation QR - " +
              decodedString,
          });
        } else {
          this.postToLoggerAPI({
            phone: this.bookingDetails.user.contact,
            refId: this.bookingId,
            icon: "redeem",
            statusText:
              "Parker validated the session successfully by scanning validation QR - " +
              decodedString,
          });
          this.validateQRCodePending = false;
          this.validateQRCodeDialog = false;
          this.$store.commit(
            "SET_VALIDATED_MERCHANT",
            result?.data?.merchantName
          );
          this.$router.replace({ path: "/validatesuccess" });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onValidateText() {
      this.validateBookingPending = true;
      let result = await this.validateBooking(this.validateBookingTxt);
      if (!result.data.status) {
        this.alertMsg = result?.data?.message
          ? result.data.message
          : "Validation not recognized. Please try again";
        this.alertDialog = true;
        this.validateBookingPending = false;
        this.postToLoggerAPI({
          phone: this.bookingDetails.user.contact,
          refId: this.bookingId,
          error: this.invalidSMSMsg,
          icon: "redeem",
          statusText:
            "Error in validating the session on entering validation code - " +
            this.validateBookingTxt,
        });
      } else {
        this.postToLoggerAPI({
          phone: this.bookingDetails.user.contact,
          refId: this.bookingId,
          icon: "redeem",
          statusText:
            "Parker validated the session successfully by entering validation code - " +
            this.validateBookingTxt,
        });
        this.validateBookingPending = false;
        this.validateBookingDialog = false;
        this.$store.commit(
          "SET_VALIDATED_MERCHANT",
          result?.data?.merchantName
        );
        this.$router.replace({ path: "/validatesuccess" });
      }
    },
    async validateBooking(code) {
      try {
        let validateResult = await APIHelper(
          "POST",
          "/api/v1/validate/" + this.bookingId,
          {
            coupon: code,
            zid: this.bookingDetails.zone.locationCode
          }
        );
        return validateResult;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    openValidationDialog() {
      this.validateBookingDialog = true;
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    uppercase() {
      if (rules.alphaNumeric(this.licencePlate) === "Must be alphanumeric") {
        this.licencePlate = this.licencePlate.replace(/[^\w\s]/gi, '')
      }
      this.licencePlate = this.licencePlate.trim()
      this.licencePlate = this.licencePlate.toUpperCase();
    },
    /**
* @method getZoneDetails fetch the zone details and commit SET_ZONE_DETAILS mutation.
*/
    async getZoneDetails(searchParamKey, searchParamValue) {
      try {
        var zoneDetails = await APIHelper(
          "GET",
          "/api/v1/user/config" + searchParamKey + "=" + searchParamValue
        );
        this.$store.commit("SET_ZONE_DETAILS", zoneDetails.data?.zone);
      } catch (error) {
        console.log(error);
      }
    },
    calculateExitDateTime() {
      if (this.bookingDetails?.zone?.timezone &&
        this.bookingDetails?.booking?.entryMode == "OD") {
        //   var currentTime =
        // new Date().getTime() +
        // (Number(this.bookingDetails.zone.timezoneoffset) * 1000 +
        //   Number(this.bookingDetails.zone.timezonedstoffset) * 1000);
        var currentTime = dateToTimeZoneDate(new Date(), this.bookingDetails.zone.timezone);
        var exitTime = new Date(this.formatExitDateTime(Number(this.bookingDetails.booking.exittime), 'date'));
        this.$store.commit("SET_EXIT_TIME_TIME_STATUS", this.exitDateTime < 0);
        differenceInMilliseconds(exitTime, currentTime) < 0 ? clearInterval(this.exitInterval) : "";
        return differenceInMilliseconds(exitTime, currentTime);
      }
      return 0

    },

    /** @methods to find the the UTC date 
    @params the bookings date value , type 'exit (2023-12-02)' or 'formatdate (12/02/2023)'
    */
    formatUTCDefaultDate(defaultDate, type) {
      let utcDate = new Date(defaultDate * 1000).toUTCString();
      let month = utcDate.split(",")[1].split(" ")[2]; // Jan
      let date = utcDate.split(",")[1].split(" ")[1]; // 27
      let year = utcDate.split(",")[1].split(" ")[3]; //2023
      let exitformat = format(new Date(month + " " + date + " " + year), 'yyyy-MM-dd');
      let dateformat = format(new Date(month + " " + date + " " + year), "MM/dd/yyyy");
      return type === "exit" ? exitformat : dateformat;

    },
    formatExitDateTime(time, val) {
      if (time !== null && time !== undefined && time != '') {
        let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
        let month = utcDate.split(",")[1].split(" ")[2]; // Jan
        let date = utcDate.split(",")[1].split(" ")[1]; // 27
        let year = utcDate.split(",")[1].split(" ")[3];
        let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
        let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
        let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
        formatHr = Number(hr) == 0 ? 12 : formatHr;

        formatHr = Number(formatHr) >= 10 ? Number(formatHr) : '0' + formatHr; //01
        let amOrpm = Number(hr) >= 12 ? "p" : "a"; //PM
        let ampm = Number(hr) >= 12 ? "PM" : "AM";
        switch (val) {
          case 'hour': {
            return formatHr + amOrpm;
          }
          case 'mins': {
            return min
          }
          case 'date': {
            return month + " " + date + ", " + year + " " + formatHr + ":" + min + " " + ampm;
          }
          case 'expire': {
            return formatHr + ":" + min + " " + ampm + ' on ' + month + " " + date
          }
        }
      }
      //Jan 27, 01:59 PM
    },
    formatDateTime(time) {
      let utcDate = new Date(time * 1000).toUTCString(); //"Wed, 27 Jan 2021 13:59:04 GMT"
      let month = utcDate.split(",")[1].split(" ")[2]; // Jan
      let date = utcDate.split(",")[1].split(" ")[1]; // 27
      let hr = utcDate.split(",")[1].split(" ")[4].split(":")[0]; // 13
      let min = utcDate.split(",")[1].split(" ")[4].split(":")[1]; //59
      let formatHr = Number(hr) > 12 ? Number(hr) - 12 : Number(hr); // 1
      formatHr = Number(formatHr) >= 10 ? Number(formatHr) : "0" + formatHr; //01
      let amOrpm = Number(hr) >= 12 ? "PM" : "AM"; //PM
      return month + " " + date + ", " + formatHr + ":" + min + " " + amOrpm; //Jan 27, 01:59 PM
    },
  },
  watch: {
    disableAPGP() {
      if (this.disableAPGP) {
        this.showAddPayment = true;
      }
      else {
        this.showAddPayment = false
      }
    },
    // exitTimeSelected(newVal, oldVal) {
    //   if (oldVal != null && newVal != null && oldVal?.a != newVal?.a) {
    //     this.exitTimeSelected = Object.assign(this.exitTimeSelected, { hh: "", mm: "" })
    //   }
    //   if (oldVal != null && newVal != null && oldVal?.hh != newVal?.hh) {
    //     this.exitTimeSelected = Object.assign(this.exitTimeSelected, { mm: "" })
    //   }
    //   this.setTimePicker(newVal);
    // },
    // time(newVal, oldVal) {
    //   if (oldVal != null && newVal != null && oldVal != newVal && oldVal != "" && newVal != "" && (isValid(newVal))) {
    //     this.round(newVal);
    //   }
    // },
    // exitDate(newVal, oldVal) {
    //   let exitdatetime = this.formatExitDateTime(this.bookingDetails?.booking?.exittime, 'date');
    //   this.dateFormatted = this.formatDate(this.exitDate)
    //   if (oldVal != newVal && this.exitDate == format(
    //     new Date(exitdatetime),
    //     "yyyy-MM-dd"
    //   )) {
    //     let selectedHours =
    //       format(
    //         new Date(exitdatetime),
    //         "hh"
    //       )

    //     let selectedMins =
    //       format(
    //         new Date(exitdatetime)
    //         ,
    //         "mm"
    //       )
    //     let selectedAMPM =
    //       format(
    //         new Date(exitdatetime),
    //         "aaa"
    //       )
    //     this.exitTimeSelected = Object.assign({}, {
    //       hh: selectedHours,
    //       mm: selectedMins,
    //       a: selectedAMPM
    //     });
    //     this.round()
    //     this.getRateCall = true;
    //   }
    //   else {
    //     this.getRateCall = false
    //   }
    // },
  },

  computed: {
    isPreAuthEnabled() {
      return Object.hasOwnProperty.call(this.bookingDetails, 'zone') && Object.hasOwnProperty.call(this.bookingDetails?.zone, 'preAuth') && Object.hasOwnProperty.call(this.bookingDetails?.zone?.preAuth, 'enabled') && this.bookingDetails?.zone?.preAuth?.enabled == '1'
    },
    resEndTime() {
      return this.odvExtension != null && this.odvExtension != undefined && this.odvExtension != "" ? format(this.odvExtension, 'yyyy-MM-dd HH:mm') : ""
    },
    timePickerConfig() {
      return Object.hasOwnProperty.call(this.bookingDetails, 'zone') && Object.hasOwnProperty.call(this.bookingDetails.zone, 'timePickerConfiguration') && this.bookingDetails?.zone?.timePickerConfiguration !== null && this.bookingDetails?.zone?.timePickerConfiguration != undefined
    },
    disableAPGP() {
      if (!this.odvExtension) {
        return false
      }
      let timeValues = this.bookingDetails?.zone?.customizedEndTime.split(':')
      let extendedVal = set(this.odvExtension, { hours: timeValues[0], minutes: timeValues[1], seconds: timeValues[2] })

      return this.bookingDetails?.zone?.timePickerConfigurtion == '1' ? differenceInMinutes(this.odvExtension, new Date(this.formatExitDateTime(this.bookingDetails?.booking?.entrytime, 'date'))) > 1425 && this.bookingDetails?.booking?.paymentType != 'Card' : differenceInMinutes(extendedVal, new Date(this.formatExitDateTime(this.bookingDetails?.booking?.entrytime, 'date'))) > 1425 && this.bookingDetails?.booking?.paymentType != 'Card'
    },
    formattedDateTime() {
      return this.odvExtension !== null && this.odvExtension !== "" ? format(this.odvExtension, 'iii, LLL dd') : ""
    },
    formattedYear() {
      return this.odvExtension !== null && this.odvExtension !== "" ? format(this.odvExtension, 'yyyy') : ""
    },
    ...mapGetters({
      bookingId: "getterBookingId",
      zoneDetails: "getterZoneDetails",
      onDemandZoneDetails: 'getterOnDemandZoneDetails',
      bookingDetails: "getterBookingDetails",
    }),
    formattedTime() {
      return this.odvExtension ? format(this.odvExtension, 'yyyy-MM-dd hh:mm aa') : ""
    },
    formattedExitTime() {
      return this.bookingDetails?.booking?.exittime ? this.formatDateTime(this.bookingDetails.booking.exittime) : ""
      //  this.bookingDetails?.booking?.exittime ? format(
      // new Date((Number(this.bookingDetails.booking.exittime)  + Number(this.bookingDetails.zone.timezoneoffset))* 1000),
      //   "hh:mm a, LLL dd yyyy" 
      // ) : ""
    },
    checkout_content() {
      return 'Checkout before ' + this.formatExitDateTime(this.bookingDetails.booking.exittime, 'expire')
    },
    booking_content() {
      return this.bookingDetails?.booking?.entrytime ? this.formatDateTime(this.bookingDetails?.booking?.entrytime).split(",")[1] + " on " + this.formatDateTime(this.bookingDetails?.booking?.entrytime).split(",")[0] : ""
      // let dateTime = formatDateTime(
      //   Number(this.bookingDetails.booking.entrytime)
      // ); 
      // return dateTime[0] + " on " + dateTime[1] + " " + dateTime[2];
    },
    dateTextFormat() {
      var event = new Date(this.date);
      return event.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
    },
    computedDateFormatted() {
      return this.odvExtension !== null && this.odvExtension !== "" && this.odvExtension !== undefined ? format(this.odvExtension, 'yyyy/MM/dd') : ""
    },
    disableExtend() {
      let disabled = (
        this.odvExtension === null || this.odvExtension === "" || this.odvExtension === undefined || this.estimate === null || this.estimate === undefined || Object.keys(this.estimate).length === 0 || this.disableAPGP)
      return disabled;
    },
    countDownExitTime() {
      if (this.exitDateTime < 0) {
        this.$store.commit("SET_VALIDATED_TIME_STATUS", true);
        clearInterval(this.exitTimeInterval);
      }
      return secondsToHms(this.exitDateTime / 1000 + 59);
    },
  },
  mounted() {
    this.exitDateTime = this.calculateExitDateTime();
    this.exitTimeInterval = setInterval(() => {
      if (
        this.bookingDetails?.zone?.timezone &&
        this.bookingDetails?.booking?.entryMode == "OD"
      ) {
        this.exitDateTime = this.calculateExitDateTime();
      }
    }, 1000);
    EventBus.$on('EXIT_TIME_SELECTED', (duration) => {
      this.applyDuration(duration);
      this.dateTimeChange();
      this.sheet = false
    })
    EventBus.$on('SET_TIME', () => {
      this.onTimePickerChange();
    })
    EventBus.$on("validation-qr-result", (result) => {
      this.onValidateQRCode(result);
    });
  },
}
</script>
<style lang="less">
.add_licence_plate_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.booking_header {
  position: absolute;
  bottom: 72px;
  top: 20px;
  left: 16px;
  font-size: 18px;
  font-weight: bolder;
}

.time_ago {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.0073529412em !important;
}

.time_ago_context {
  font-size: 14px;
  font-weight: 500;
}

.validated_time {
  font-size: 13px;
  font-weight: 500;
}

.booking_divider {
  border-color: white !important;
  margin-top: 12px;
}

.booking_divider_div {
  position: absolute;
  bottom: 60px;
  width: 100%;
}

.booking_content {
  position: absolute;
  bottom: 18px;
  left: 16px;
  font-size: 13px;
  font-weight: normal;
}

.add_credit_card_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.validation-pending {
  position: absolute !important;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.qr_text {
  font-size: 13px;
}

.exit_dialog_btn {
  width: 100%;
  background-color: #f2555c !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  letter-spacing: normal !important;
  height: 50px !important;
}

.sms-input input {
  font-size: 36px;
  font-weight: 500;
  color: #44515a !important;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-top: 26px !important;
}

.exit-option-text {
  font-size: 14px;
}

.sms-caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
}

.qrcode-stream-wrapper video {
  max-height: 300px;
}

.counter-content-position {
  position: relative;
}

.grace-period-image {
  position: absolute !important;
  right: -51px;
  bottom: 5px;
}

.fabIcon {
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}

#qr-code-full-region__dashboard {
  display: none;
}

#qr-code-full-region>video {
  width: 100% !important;
}

#validation-qr-code>video {
  width: 100% !important;
}

#qr-code-full-region img {
  display: none;
}

.no-upper-case {
  text-transform: unset !important;
}

.time-picker-input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  font-size: 16px;
  font-family: "Work Sans";
}

.time-picker-input :focus-visible {
  outline-color: #1976d2;
}

.vue__time-picker input.display-time {
  padding: 0px !important;
  border-bottom: 1px solid #767676;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
}

.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #f2555c !important;
}

.thankyou_header {
  position: absolute;
  bottom: 20px;
  left: 16px;
  font-size: 24px;
  font-weight: bolder;
}

[data-key='am'] {
  border: 0.2px solid red;
  border-radius: 2px;
  margin-left: 2px;
}

[data-key='pm'] {
  border: 0.2px solid red;
  margin-top: 0.5em;
  margin-left: 2px;
}

.hours .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px
}

.apms .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  margin-left: 1px;
  -webkit-text-fill-color: white;
  margin-bottom: 10px
}

.hours::-webkit-scrollbar {
  display: none;
}

.minutes::-webkit-scrollbar {
  display: none;
}

.minutes .hint {
  position: sticky;
  top: 0;
  text-align: center;
  background: #68808c;
  z-index: 1;
  -webkit-text-fill-color: white;
  margin-bottom: 10px
}

.booking_date {
  font-weight: normal;
  font-size: 13px;
  color: #3d3f3c !important;
}

.booking_date_header {
  color: #3d3f3c !important;
  font-weight: bold;
  font-size: 14px;
}

.total-parking {
  color: #3d3f3c;
  font-size: 0.875rem !important;
  font-weight: 500;
  line-height: 2.25rem;
  letter-spacing: 0.0892857143em !important;
  text-transform: uppercase !important;
}

.total {
  border: 1px solid #ffbc1f;
}

.amount-text {
  color: #3d3f3c;
  font-size: 34px;
}

.amount-content-position {
  position: relative;
}



#style {
  color: black !important;
}

.alert-msg-box {
  background-color: #ececec;
  padding: 15px 20px 10px;
  margin: 0px;
  width: 100%;
  height: min-content;
}

.preauth_header {
  position: relative;
  margin-top: 8px;
  font-size: 18px;
  font-weight: bolder;
  z-index: 10 !important;
}
</style>
